import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { create } from "zustand";

export const useProjectStore = create((set) => ({
  projects: [],
  project: [],
  isLoading: false,

  // 실시간 project 호출(admin에서 사용)
  getSnapshot: async () => {
    try {
      set({ isLoading: true });
      const getProjectQuery = query(
        collection(db, "project"),
        orderBy("createAt", "desc")
      );
      const unsubscribe = await onSnapshot(getProjectQuery, (snapshot) => {
        const projects = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        set({ projects, isLoading: false });
      });
      return unsubscribe;
    } catch (error) {
      set({ isLoading: false });
      console.error("스냅샷 가져오는 중 에러 발생: ", error);
    }
  },

  // project 호출(navigation에서 사용중, 1회성)
  getProjects: async () => {
    set({ isLoading: true });
    try {
      const getProjectQuery = query(
        collection(db, "project"),
        orderBy("createAt", "desc")
      );
      const querySnapshot = await getDocs(getProjectQuery);
      const projects = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ projects });
    } catch (error) {
      console.error("전체 프로젝트를 가져오는 도중 문제 발생: ", error);
    }
    set({ isLoading: false });
  },

  // project 삭제
  deleteProject: async (removeId) => {
    try {
      set({ isLoading: true });
      const docRef = doc(db, "project", removeId);
      await deleteDoc(docRef);
    } catch (error) {
      console.error("프로젝트 삭제 중 문제 발생: ", error);
    }
    set({ isLoading: false });
  },

  // project 생성
  // 타이틀 명으로 생성되며, 내부 데이터는 해당 프로젝트 수정 모드로 들어가서 조작
  createProject: async (title) => {
    try {
      set({ isLoading: true });
      const docRef = await addDoc(collection(db, "project"), {
        createAt: serverTimestamp(),
        title,
        home: false,
        description: "",
        hashTags: [],
        mediaItems: [],
      });
      set({ isLoading: false });
      return docRef;
    } catch (error) {
      console.error("새 프로젝틑를 생성하는 도중 문제 발생: ", error);
      set({ isLoading: false });
    }
  },

  // doc.id로 project 불러오기
  getProject: async (docId) => {
    try {
      const docRef = doc(db, "project", docId);
      const docSnap = await getDoc(docRef);
      let result = undefined;
      if (docSnap.exists()) {
        const data = docSnap.data();
        result = {
          id: docSnap.id,
          home: data.home,
          title: data.title,
          description: data.description,
          hashTags: data.hashTags,
          mediaItems: data.mediaItems,
        };
      }
      set({ isLoading: false });
      return result;
    } catch (error) {
      console.error("요청하신 프로젝트 정보를 불러오는 중 문제 발생: ", error);
      set({ isLoading: false });
    }
  },
  // project 수정
  updateProject: async (newProject, target) => {
    try {
      set({ isLoading: true });
      const { id, home, title, description, hashTags, mediaItems } = newProject;
      const docRef = doc(db, "project", id);
      if (target) {
        // 부분 업데이트
        switch (target) {
          case "mediaItems":
            await updateDoc(docRef, { mediaItems });
            break;
          case "home":
            await updateDoc(docRef, { home });
            break;
          default:
            break;
        }
      } else {
        // 전체 업데이트
        console.log(hashTags, description);
        console.log(home);

        await updateDoc(docRef, {
          home,
          title,
          description,
          hashTags,
          mediaItems,
        });
      }
      return true;
    } catch (error) {
      console.error("프로젝트 내용 변경 중 문제가 발생: ", error);
    }
    set({ isLoading: false });
  },

  // upload Image (using appwrite + firebase)
  uploadImage: async (file) => {
    // 압축 코드
  },
}));
