import styled, { keyframes } from "styled-components";

const Email = ({ text }) => {
  return (
    <Wrapper>
      <div>
        <Span>{text}</Span>
        <Span>{text}</Span>
        <Span>{text}</Span>
        <Span>{text}</Span>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100vw;
  height: 25vh;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  margin-top: -2px;
  overflow-x: hidden;
  white-space: nowrap;
  & div {
    display: block;
    animation: ${keyframes`from{
      transform: translateX(0);
    }to{
      transform: translateX(-25%);
    }`} 8s infinite linear;
  }
  @media (max-width: 768px) {
    height: 16vh;
  }
`;

const Span = styled.span`
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 20dvh;
  line-height: 0.5;
  &::after {
    content: "";
    padding: 0 10vw;
  }
  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 14dvh;
  }
`;

export default Email;
